import React from "react"
import { Box, Button, Flex, Link as LinkExt, Stack } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { GatsbyImage } from "gatsby-plugin-image"

import {
  IntroText,
  PrimaryPagesHero,
  SectionHeading,
  Stats,
  TwoCols,
  VideoPlayer,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Container,
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  CentersGrid,
  LeadershipGrid,
  HighlightedProvider,
  HopeAndHealing,
  Seo,
} from "../../components/elements"

const AboutUs = ({ data, pageContext }) => {
  const page = data.datoCmsCancerAbout
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Seo
        title={page.seo.title}
        description={page.seo.description}
        image={page.seo.image && page.seo.image.url}
      />

      <PrimaryPagesHero page={page}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={page.title}
        />
      </PrimaryPagesHero>

      <Main>
        {/* IntroContent */}
        <Section id="intro">
          <Container>
            {page.multidisciplinaryCareVideo.url ? (
              <Box width={["full", "full", 3 / 4]} mx="auto" mb={12}>
                <VideoPlayer url={page.multidisciplinaryCareVideo.url} />
              </Box>
            ) : null}

            <Box width="full">
              <IntroText>{page.introContent}</IntroText>
            </Box>
            <Stack direction={["column", "column", "row"]} spacing={12}>
              <Box width={["full", "full", "1 / 2"]} alignSelf="center">
                <TwoCols
                  content={`${page.contentNode.childMarkdownRemark.html}`}
                />
                <LinkExt variant="solid" href={page.annualReport} isExternal>
                  <Button as="span" my={3} mr={[0, 6]}>
                    View Annual Report
                  </Button>
                </LinkExt>
                
                  <LinkExt variant="solid" href="/about-us/accreditations-designations">
                  <Button as="span" my={3}>
                    View Accreditations
                  </Button>
                </LinkExt>
              </Box>
            </Stack>
          </Container>
        </Section>

        <Section>
          <Container>
            <Box width="full">
              {page.awards.length > 0 ? (
                <Flex wrap="wrap" width="full">
                  {page.awards.map((award) => (
                    <Box key={award.id} width={[1 / 4]} px={[3, 3, 6, 9]}>
                      <LinkExt href={award.url} isExternal>
                        <GatsbyImage
                          image={award.logo.gatsbyImageData}
                          alt="award logo"
                          sx={{
                            objectFit: "contain",
                          }}
                        />
                      </LinkExt>
                    </Box>
                  ))}
                </Flex>
              ) : null}
            </Box>
          </Container>
        </Section>

        {/* Quick Facts */}
        <Section
          id="facts"
          bgGradient="linear(to-r, primary, blue.600)"
          sx={{
            "*": {
              color: "white",
            },
          }}
        >
          <Container>
            <Box width="full">
              <SectionHeading textAlign="center">
                2024 Quick Stats
              </SectionHeading>
            </Box>
            <Box width="full">
              <Stats page={page} />
            </Box>
          </Container>
        </Section>

        {/* Centers */}
        <Section id="locations" bg="lightgray">
          <Container>
            <Flex flexWrap="wrap">
              <Box width="full">
                <SectionHeading textAlign="center">
                  Locations – Care Close to Home
                </SectionHeading>
              </Box>
              <CentersGrid />
            </Flex>
          </Container>
        </Section>

        {/* Providers & Leadership */}
        <Section>
          <Container>
            <Flex flexWrap="wrap">
              <Box width="full">
                <SectionHeading textAlign="center" id="highlighted-providers">
                  Highlighted Providers
                </SectionHeading>
              </Box>

              <Box width="full">
                <HighlightedProvider />
              </Box>

              <Box width="full" mt={24} id="leadership">
                <SectionHeading textAlign="center">Leadership</SectionHeading>
              </Box>

              <Box width="full">
                <LeadershipGrid />
              </Box>
            </Flex>
          </Container>
        </Section>

        <Section>
          <Container>
            <HopeAndHealing />
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default AboutUs

export const query = graphql`
  {
    datoCmsCancerAbout {
      title
      slug
      contentNode {
        childMarkdownRemark {
          html
        }
        id
      }
      annualReport
      introContent
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { ar: "1600:720", fit: "crop", crop: "focalpoint" }
        )
      }
      awards {
        id
        logo {
          gatsbyImageData(imgixParams: {})
        }
        url
      }
      multidisciplinaryCareVideo {
        url
      }
      quicklinks {
        externalLinkUrl
        id
        internalLinkUrl
        linkTitle
      }
      stats {
        id
        number
        text
      }
      seo {
        description
        title
        image {
          url
        }
      }
    }
  }
`
