import React from "react"
import { Flex } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Script } from "gatsby"
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav"

import {
  Footer,
  Header,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout/"
import SearchContainer from "../../../../components/elements/SearchContainer"
import Global from "@giraldomac/gatsby-theme-wvumedicine/src/utils/global"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            siteTitle
            facebook
            twitter
            phone
            address
            email
          }
        }
      }
    `}
    render={(data) => {
      return (
        <>
          <Global />

          <Flex flexDirection="column" flexWrap="nowrap">
            <SkipNavLink data-datocms-noindex />

            <Header data-datocms-noindex />
            <SearchContainer />

            <SkipNavContent data-datocms-noindex />
            {children}
              
            <Footer
              siteName={data.site.siteMetadata.siteTitle}
              facebook={data.site.siteMetadata.facebook}
              twitter={data.site.siteMetadata.twitter}
              phone={data.site.siteMetadata.phone}
              address={data.site.siteMetadata.address}
              email={data.site.siteMetadata.email}
              data-datocms-noindex
            />
          </Flex>
        </>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
